export default {
    id: null,
    nome: null,
    descricao: null,
    metaReceita: null,
    metaDespesa: null,
    saldo: null,
    status: 1,
    parent: null
}
