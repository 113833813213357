<template>
  <form v-if="!preventBind" @submit.prevent="save" class="w-m-content financeiro-window">
    <div class="w-m-header">
      <p class="font-14 font-bold no-margin no-select" style="color: #293e52">Centro de Custo</p>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size1 fin-form">
      <e-row>
        <e-col style="padding-right: 20px">
          <e-row mr>
            <e-col>
              <erp-s-field label="Nome">
                <erp-input autofocus placeholder="Defina um nome para seu Centro de Custo" v-model="centroCusto.nome"/>
              </erp-s-field>
            </e-col>
          </e-row>

<!--          <e-row class="m-t m-b" mr>
            <e-col>
              <div style="border-top: #CCCCCC 1px solid"></div>
            </e-col>
          </e-row>-->
          <e-row class="m-t-sm" mr>
            <e-col>
              <erp-s-field label="Observações">
                <textarea class="app-input input-textarea full-width" rows="4" v-model="centroCusto.descricao"/>
              </erp-s-field>
            </e-col>
          </e-row>
          <e-row class="m-t-sm" mr>
            <e-col>
              <erp-s-field label="Status">
                <erp-select
                    :options="[
                          {label: 'Ativo', value: 1},
                          {label: 'Inativo', value: 0},
                      ]"
                    v-model="centroCusto.status" />
              </erp-s-field>
            </e-col>
          </e-row>
        </e-col>
        <e-col style="max-width: 250px; border-left: #D6DEE5 1px solid; min-height: 400px; padding-left: 20px">
          <e-row mr>
            <e-col>
              <erp-s-field label="Meta de Receita">
                <erp-input placeholder="Meta de Receita" v-model="centroCusto.metaReceita" v-money="money" />
              </erp-s-field>
            </e-col>
          </e-row>
          <e-row mr>
            <e-col>
              <erp-s-field label="Meta de Despesa">
                <erp-input placeholder="Meta de Despesa" v-model="centroCusto.metaDespesa" v-money="money" />
              </erp-s-field>
            </e-col>
          </e-row>
          <e-row mr>
            <e-col>
              <erp-s-field label="Relação com outro Centro de Ccusto">
                <centro-custo-input v-model="centroCusto.parent" />
              </erp-s-field>
            </e-col>
          </e-row>
        </e-col>
      </e-row>
    </div>
    <div class="w-m-footer text-right footer-modern"
         style="justify-content: space-between !important; display: flex; flex-direction: row">
      <div class="text-left">

      </div>
      <div class="text-right">
        <u-btn @click="$uloc.window.close($root.wid)" label="Cancelar" no-caps
               :color="'white'"
               text-color="grey-8"
               class="b-radius-3px btn-white m-r-sm"/>
        <u-btn :loading="loading" type="submit" label="Salvar" no-caps
               :color="'green'"
               class="b-radius-3px"/>
      </div>
    </div>
  </form>
</template>

<script>
// import {UTooltip} from "uloc-vue"
import ERow from '@/components/layout/components/Row'
import {ErpInput, ErpSField, ErpSelect} from 'uloc-vue-plugin-erp'
import ECol from '@/components/layout/components/Col'
import {newCentroCusto, updateCentroCusto, findCentroCusto} from "@/domain/financeiro/services/centroCusto"
// import DatetimeInput from "@/reuse/input/Datetime"
import mockCentroCusto from "@/domain/financeiro/helpers/mockCentroCusto"
import {convertRealToMoney, REAL_BRL} from "@/utils/money"
import {VMoney} from "v-money"
import CentroCustoInput from "@/components/financeiro/components/input/CentroCustoInput";
// import {datePtToEn} from "@/utils/date"

export default {
  name: 'CentroCustoWindowModern',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [],
  directives: {money: VMoney},
  components: {
    CentroCustoInput,
    //DatetimeInput,
    ErpInput,
    ErpSField,
    ERow,
    ECol,
    ErpSelect,
    //UTooltip
  },
  props: ['id', 'options', 'router'],
  data() {
    let mock = JSON.parse(JSON.stringify(mockCentroCusto))
    return {
      money: REAL_BRL,
      loading: false,
      success: false,
      status: '',
      preventBind: false,
      centroCusto: mock
    }
  },
  mounted() {
    if (this.id) {
      this.load()
    }
  },
  computed: {
  },
  methods: {
    load() {
      this.loading = true
      findCentroCusto(this.id)
          .then(response => {
            console.log(response)
            if (response.data.id) {
              this.centroCusto.id = response.data.id
            }
            this.preventBind = true
            this.centroCusto = Object.assign({}, this.centroCusto, this.prepareToEdit(response.data))
            this.$nextTick(() => {
              setTimeout(() => {
                this.preventBind = false
              }, 1)
            })
            // this.task = response.data
            console.log(this.centroCusto)
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    prepareToSave() {
      const data = JSON.parse(JSON.stringify(this.centroCusto))
      const valores = ['metaReceita', 'metaDespesa']
      valores.map(key => {
        data[key] = convertRealToMoney(data[key])
        if (!data[key]) {
          data[key] = null
        }
      })

      const relacoes = ['parent']
      relacoes.map(key => {
        if (data[key] && data[key].id) {
          data[key] = data[key].id
        }
      })
      if (data.parent && data.parent.id && data.parent.id === data.id) {
        data.parent.id = null // Não pode um centro de custo se relacionar com ele mesmo
      }
      return data
    },
    prepareToEdit(data) {
      const valores = ['metaReceita', 'metaDespesa']
      valores.map(key => {
        data[key] = this.$options.filters.moeda(data[key])
        if (!data[key]) {
          data[key] = null
        }
      })
      /*const datas = ['dataCompetencia', 'dataVencimento', 'dataPagamento']
      datas.map(key => {
        if (data[key] && data[key].date) {
          data[key] = this.$options.filters.formatDate(data[key].date)
        }
      })*/
      return data
    },
    save() {
      this.loading = true
      let data
      try {
        data = this.prepareToSave()
      } catch (e) {
        console.log(e)
        this.alertApiError(null, 'Erro de validação', null, `${e.message} Código L-${e.lineNumber}`)
        this.loading = false
        return
      }
      let method = newCentroCusto
      if (this.centroCusto.id) {
        method = updateCentroCusto
      }
      method(data)
          .then(response => {
            this.loading = false
            console.log(response)
            this.centroCusto.id = response.data.id
            this.$uloc.window.emit(this.$root.wid, 'created', response.data)
            // this.$uloc.window.close(this.$root.wid)
            if (this.id || this.centroCusto.id) {
              this.dg()
            } else {
              this.$uloc.notify({
                color: 'black',
                message: `Novo centro de custo com ID #"${response.data.id}" criado.`,
                position: 'bottom-left',
                /*actions: [{
                  label: 'Visualizar',
                  handler: () => {
                    this.gotoPerson(response.data)
                  }
                }]*/
              })
            }
          })
          .catch(error => {
            console.log(error)
            this.loading = false
            this.alertApiError(error)
          })
      this.$uloc.window.emit(this.$root.wid, 'updated', this.lista)
      this.success = true
    }
  }
}
</script>
